let env = 'dev',
  // apiBaseUrlRoot = 'http://api-simoneverte.sentinelles-technologies.com/',
  apiBaseUrlRoot = 'https://api.simoneverte.fr',
  VUE_APP_PUSHER_APP_KEY = 'dd5355630654679a5bb8',
  VUE_APP_PUSHER_APP_CLUSTER = 'eu',
  GOOGLE_AUTH_API_CLIENT_ID = '198384051908-9b5j1qp0c0t04l7vj8ipqhit4jkqn0np';

// PREPROD
if (document.location.host.indexOf('simoneverte.sentinelles-technologies.com') >= 0) {
  env = 'preprod';
}
// DEV + PREPROD
apiBaseUrlRoot = apiBaseUrlRoot + env;

// PROD
if (document.location.host.indexOf('simoneverte.fr') >= 0) {
  apiBaseUrlRoot = 'https://api.simoneverte.fr';
  VUE_APP_PUSHER_APP_KEY = '34b8778438993d3e7034';
  VUE_APP_PUSHER_APP_CLUSTER = 'eu';
}

export default {
  env: env,
  dureeSession: 60 * 60 * 1000, 
  apiBaseUrl:  apiBaseUrlRoot + '/simoneverteapi/public/api',
  avatarsUrl: 'https://files.simoneverte.fr/avatars/' + env + '/',
  pourcentage_fees: 0.0,
  autres_fees: 0, // 2.0,
  montant_retrait_degressif: 1,
  pusher_app_key: VUE_APP_PUSHER_APP_KEY,
  pusher_app_cluster: VUE_APP_PUSHER_APP_CLUSTER,
  google_auth_api_client_id: `${GOOGLE_AUTH_API_CLIENT_ID}.apps.googleusercontent.com`
}