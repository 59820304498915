<template>
    <div id="main" style="min-height:70vh;">
        <section id="connexion">
            <div class="connexion-content" v-if="!mot_de_passe_oublie && !reset_mot_de_passe">
                <h1 class="titre">Vos identifiants</h1>
                <h2 
                    v-show="erreur"
                    class="erreur"
                >
                  {{ erreur }}
                </h2>
                
                <div class="input-group">
                    <input
                        type="email"
                        class="input-detail"
                        v-model="email"
                        v-on:focus="erreur = ''"
                        placeholder="Email" />
                </div>
                <div class="input-group">
                  <input 
                    id="connexion-password"
                    :type="passwordType"
                    class="input-detail" 
                    v-model="password"
                    v-on:focus="erreur = ''"
                    v-on:keyup.enter="connexion"
                    placeholder="Mot de passe" />
                  <span 
                    class="decouvrir mdi mdi-eye"
                    v-show="passwordType == 'password'"
                    @click="passwordType = 'text'">
                  </span>
                  <span 
                    class="decouvrir mdi mdi-eye-off"
                    v-show="passwordType == 'text'"
                    @click="passwordType = 'password'">
                  </span>
                </div>
                <div class="input-group" v-show="nb_essais > 0 && nb_essais < 5">
                  <div style="width: 39%; margin: 12px auto 0;">
                    <a v-on:click="motDePasseOublie">Je crois que j'ai oublié mon mot de passe</a>
                  </div>
                </div>
                <!--<div class="input-group">
                  <div style="width: 39%; margin: 0 auto;">
                    <v-checkbox
                      class="checkbox"
                      v-model="checkbox"
                      label="Me maintenir connecté ?">
                    </v-checkbox>
                  </div>
                </div>-->
                <div class="bouton">
                    <input 
                        type="button"
                        class="btn"
                        v-show="!loaded && !loading"
                        v-on:click="connexion"
                        value="Connexion" />
                    
                    <button  
                      class="btn"
                      v-show="loading">
                      <span class="mdi mdi-spin mdi-loading"></span>
                    </button>

                    <button  
                        class="btn centre valide"
                        v-show="loaded">
                        Connexion réussie <span class="mdi mdi-check"></span>
                    </button>
                </div>
                <hr />
                <v-row
                  class="intro-content"
                  align="center"
                  justify="center">
                  <div ref="googleLoginBtn" />
                </v-row>
            </div>
            
            <div class="connexion-content" v-if="mot_de_passe_oublie">
                <label class="titre">Mot de passe oublié ?</label>
                
                <h4 
                    v-show="erreur"
                    class="erreur"
                >
                  {{ erreur }}
                </h4>
                
                <h4 
                    v-show="message"
                    class="message"
                >
                  {{ message }}
                </h4>
                
                <div class="input-group">
                    <input
                        type="email"
                        class="input-detail"
                        v-model="email_recuperation"
                        v-on:focus="erreur = ''; message = ''"
                        v-on:keyup.enter="retouverMotDePasse"
                        placeholder="Email" />
                </div>

                <div class="bouton">
                    <input 
                        type="button"
                        class="btn"
                        v-show="!loaded"
                        v-on:click="retouverMotDePasse"
                        value="Retrouvez un mot de passe valide" />
                    <button  
                        class="btn centre valide"
                        v-show="loaded">
                        Connexion réussie <span class="mdi mdi-check"></span>
                    </button>
                </div>

            </div>

            <div class="connexion-content" v-if="reset_mot_de_passe">
                <label class="titre">Votre nouveau mot de passe</label>
                
                <h4 
                    v-show="erreur"
                    class="erreur"
                >
                  {{ erreur }}
                </h4>
                
                <h4 
                    v-show="message"
                    class="message"
                >
                  {{ message }}
                </h4>
                
                <div class="input-group">
                  <input 
                      id="password"
                      name="password" 
                      :type="passwordType"
                      placeholder="Au moins 10 caractères" 
                      v-model="n_password" />
                  
                  <span 
                    class="decouvrir mdi mdi-eye"
                    v-show="passwordType == 'password'"
                    @click="passwordType = 'text'">
                  </span>
                  <span 
                    class="decouvrir mdi mdi-eye-off"
                    v-show="passwordType == 'text'"
                    @click="passwordType = 'password'">
                  </span>
                </div>
                <div class="regles password conteneur" v-if="!isValidPassword() && n_password && n_password.length > 0">
                  <div class="regle" :class="{ 'valide': n_password.length >= 10}">* Au moins 10 caractères</div>
                  <div class="regle" :class="{ 'valide': /[A-Z]/.test(n_password)}">* Au moins une lettre majuscule</div>
                  <div class="regle" :class="{ 'valide': /[a-z]/.test(n_password)}">* Au moins une lettre minuscule</div>
                  <div class="regle" :class="{ 'valide': /[@$!%*#?&]/.test(n_password)}">* Au moins un caractère spécial (&, ?, #, *, %, @, $, ! autorisés)</div>
                  <div class="regle" :class="{ 'valide': /[0-9]/.test(n_password)}">* Au moins un chiffre</div>
                </div>
                <div class="input-group" v-show="isValidPassword()">
                  <input 
                      id="confirmation"
                      name="confirmation" 
                      type="password"
                      placeholder="On prend les mêmes et on recommence !" 
                      v-model="n_confirmation" />
                </div>

                <div class="bouton">
                    <input 
                        type="button"
                        class="btn"
                        v-on:click="enregistrerMotDePasse"
                        :disabled="!isValidPassword(2)"
                        value="Enregistrez votre nouveau un mot de passe" />
                    <button  
                        class="btn centre valide"
                        v-show="loaded">
                        Connexion réussie <span class="mdi mdi-check"></span>
                    </button>
                </div>

            </div>
        </section>
    </div>
</template>

<style>
#connexion {
    margin:auto;
    width: 100%;
    padding: 36px;
}

.connexion-content {
    text-align: center;
}

#connexion .connexion-content .input-group {
    margin: 13px 0;
}

#connexion .connexion-content .input-group .decouvrir {
    position: absolute;
    top: 9px;
    right: 32%;
    color: #999;
    cursor: pointer;
}

.titre {
  color: #17a2b8;
  margin: 9px 0 39px 0;
  font-size: 25px;
  font-weight: 600;
}

#connexion .connexion-content input {
  width: 39%;
  border-radius: 12px;
  border: 1px solid #ccc;
  height: 39px;
  margin: 0 auto;
  padding-left: 12px;
}

#connexion .connexion-content input:focus {
  border: 2px solid #17a2b8;
} 

.erreur {
  margin: 12px auto;
  font-size: 0.72em;
  letter-spacing: 0.3em;
  background-color: white;
  color: #f36161;
  border: 2px solid;
  padding: 12px 16px;
  border-radius: 12px;
  width: inherit;
  max-width: 450px;
}

.checkbox {
 display: flex;
 align-content: center;

}

@media(max-width: 480px) {  
  #connexion .connexion-content input {
    width: 75vw;
  }
  
  #connexion .connexion-content .input-group .decouvrir {
      right: 12vw;
  }
}
</style>

<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
import { AUTH_REQUEST } from '../store/actions/auth';
import router from '../router';
import axios from 'axios';
import constantes from '../constantes';

export default {
  data() {
      return {
        checkbox: true,
        email: '', 
        password: '',
        loading: false,
        loaded: false,
        erreur: null,
        message: null,
        nb_essais: 0,
        mot_de_passe_oublie: false,
        reset_mot_de_passe: false,
        email_recuperation: '',
        passwordType: 'password',
        n_password: null,
        n_confirmation: null,
        /* eslint-disable */
        regex_email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        regex_password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{10,}$/,
        /* eslint-enable */
        googleSignInParams: {
          client_id: constantes.google_auth_api_client_id
        }
      };
  },
  mounted() {
    // Titre de l'onglet
    document.title = 'Connectez-vous sur SimoneVerte.fr : le covoiturage longue distance à petits prix. Pas cher et carrément plus cool. Covoiturage, partage et vie | SimoneVerte.fr';

    if (this.$route.query.deconnexion_automatique == true) {
      this.erreur = "Vous avez été déconnecté par manque d'activité sur le site. Veuillez-vous reconnecter.";
    }

    if (this.$route.name == 'motDePasseOublie') {
      document.title = 'Récupération de mot de passe sur SimoneVerte.fr : le covoiturage longue distance à petits prix. Pas cher et carrément plus cool. Covoiturage, partage et vie | SimoneVerte.fr';
      this.mot_de_passe_oublie = true;
    }

    if (this.$route.name == 'resetMotDePasse') {
      document.title = 'Récupération de mot de passe sur SimoneVerte.fr : le covoiturage longue distance à petits prix. Pas cher et carrément plus cool. Covoiturage, partage et vie | SimoneVerte.fr';
      
      let params = this.$route.params,
        hash = params.hash,
        email = params.email;

      if (hash && email) {
        this.reset_mot_de_passe = true;
      }
    }

    // Authenfication externe
    window.google.accounts.id.initialize({
      client_id: constantes.google_auth_api_client_id,
      callback: this.handleCredentialResponse,
      auto_select: true
    })
    window.google.accounts.id.renderButton(
      this.$refs.googleLoginBtn, {
        text: 'signin_with',
        size: 'large',
        width: '300',
        theme: 'outline',
        logo_alignement: 'left'
      }
    )
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated", 
      "isProfileLoaded"
    ]),
    profile() {
      return this.getProfile;
    }
  },
  methods: {
    connexion: function() {      
      
      this.$root.$emit("start_loading");
      this.loading = true;

      // credentials
      const { email, password } = this;
      
      // Store Vuex
      this.$store.dispatch(AUTH_REQUEST, { email, password })
      .then(() => {
        this.loaded = true;

        var path = this.isProfileLoaded ? '/utilisateurs/' + this.profile.id : '/';
        if (this.$route.query.referer) {
          path = this.$route.query.referer;
        }
        router.push(
        { 
          path: path,
        });
      })
      .catch(err => {
        if (err.response.status === 401) {
          // Affichage du message
          if (err.response.data.errors && err.response.data.errors.length > 0) {
            this.erreur = err.response.data.errors[0].detail;
          }
        }
      })
      .finally(() => {
        this.$root.$emit("hide_loading");
        this.loading = false;
        this.nb_essais++;
      });      
    },

    motDePasseOublie: function() {
      document.title = 'Récupération de mot de passe sur SimoneVerte.fr : le covoiturage longue distance à petits prix. Pas cher et carrément plus cool. Covoiturage, partage et vie | SimoneVerte.fr';
      this.erreur = '';
      this.message = '';
      this.mot_de_passe_oublie = true;
      router.push({ name: 'motDePasseOublie'});
    },

    retouverMotDePasse: function() {
      let v = this.regex_email.test(this.email_recuperation);
      if (v) {
        
        this.$root.$emit("start_loading");

        axios(constantes.apiBaseUrl + '/utilisateurs?filter[email]=' + this.email_recuperation + '&filter[activated]=1')
        .then(res => {
          
          if (res.data.length == 1) {
            // Good
            var bodyFormData = new FormData();
            bodyFormData.append('email', this.email_recuperation);

            axios({
              method: 'post',
              url: constantes.apiBaseUrl + '/password/forgot',
              data: bodyFormData,
              headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(() => {
              this.erreur = '';
              this.message = "Un mail de récupération vient de vous être envoyé.";
            })
            .finally(() => this.$root.$emit("hide_loading"));
            
          } else {
            this.$root.$emit("hide_loading");
            this.erreur = "Cette adresse email n'est pas activée dans notre système.";
          }
        });
      }
    },

    isValidPassword: function(nb_password) {
      let v = this.n_password
        && this.regex_password.test(this.n_password);
      if (nb_password && nb_password === 2) {
        v = v 
          && this.n_confirmation
          && this.regex_password.test(this.n_confirmation)
          && this.n_password === this.n_confirmation;
      }
      return v;
    },

    enregistrerMotDePasse: function() {
      
      this.$root.$emit("start_loading");

      let params = this.$route.params,
        hash = params.hash,
        email = params.email;
      
      this.erreur = '';
      this.message = '';

      var bodyFormData = new FormData();
      bodyFormData.append('email', email);
      bodyFormData.append('token', hash);
      bodyFormData.append('password', this.n_password);
      bodyFormData.append('password_confirmation', this.n_confirmation);

      axios({
        method: 'post',
        url: constantes.apiBaseUrl + '/password/reset',
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(() => {
        this.erreur = '';
        this.message = "Votre nouveau mot de passe a bien été enregistré. Vous allez être redirigé vers la page de connexion. Merci !"
        this.mot_de_passe_oublie = this.reset_mot_de_passe = false;
        setTimeout(() => {
          router.push({ name: 'connexion'});
        }, 7500);
      })      
      .catch(err => {
        if (err.response.status === 400) {
          // Affichage du message
          if (err.response.data.errors && err.response.data.errors.length > 0) {
            this.erreur = "Le lien de réinitialisation de votre mot de passe a expiré, recommencez la procédure.";
            this.message = '';
          }
        } else {
          this.erreur = "Une erreur s'est produite, veuillez réessayer plus tard.";
          this.message = '';
        }
      })
      .finally(() => {
        this.$root.$emit("hide_loading");
      });       
    },

    handleCredentialResponse(response) {
      // Send the Google ID token to your server for verification
      const gUser = this.parseJwt(response.credential);
      console.log(gUser);
      // Make an API call to your Node.js server with the token
      axios.post('/auth/google/login', gUser)
        .then(response => {})
        .catch(error => {console.err(error)});
    },
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
  }
}
</script>