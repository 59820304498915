<template>

  <div id="main" style="min-height:70vh;">

    <!--==========================
      Intro Section
    ============================-->
    <section id="inscription">
        
      <div class="inscription-content">
        <div class="etape-1" v-show="!etapes[0]">
          <h1 class="titre">Votre email ?</h1>
          <div class="input-group">
            <input 
                id="email"
                name="email" 
                type="email"
                maxlength="180"
                placeholder="simone.dupont@simoneverte.fr" 
                v-model="utilisateur.email"
                v-on:change="erreurs.doublon_email = false" />
          </div>
          <div class="input-group regles password container" v-if="utilisateur.email && erreurs.doublon_email">
            <div class="regle">* Cette adresse email est déjà enregistrée.</div>
          </div>
          <div class="input-group" v-if="utilisateur.email && !erreurs.doublon_email">
              <input 
                id="avancez_1" 
                name="avancez_1" 
                class="btn"
                type="button" 
                value="Continuez" 
                v-on:click="valider_etape_1()" />
          </div>
          <hr />
          <v-row
            align="center"
            justify="center"
            style="margin-top: 36px">
            <div ref="inscriptionViaGoogle" />
          </v-row>
        </div>
        <div class="etape-2" v-show="etapes[0] && !etapes[1]">
          <label class="titre">Allons plus loin dans les présentations.</label>
          <div class="input-group">
            <input 
              id="prenom"
              name="prenom" 
              maxlength="75"
              type="text"
              placeholder="Votre prénom (exemple: Simon)" 
              v-model="utilisateur.prenom" />
          </div>
          <div class="input-group">
            <input 
              id="nom"
              name="nom" 
              maxlength="75"
              type="text"
              placeholder="Votre nom (exemple: Dupont)" 
              v-model="utilisateur.nom" />
          </div>
          <div class="input-group">
            <input 
              id="date_naissance"
              name="date_naissance"
              maxlength="10" 
              type="text"
              placeholder="Votre date de naissance (exemple: 25/12/1985)" 
              v-model="date_naissance" />
          </div>
          <div class="input-group regles password container" v-if="date_naissance && erreurs.date_naissance_invalide">
            <div class="regle">* Cette date de naissance n'est pas correcte. Merci de renseignée une date correcte.</div>
          </div>
          <div class="input-group" v-show="utilisateur.prenom && utilisateur.nom && utilisateur.date_naissance">
            <input 
              id="avancez_2"
              name="avancez_2"
              class="btn"
              type="button"
              value="Continuez"
              v-on:click="valider_etape_2()">
          </div>
        </div>
        <div class="etape-3" v-show="etapes[0] && etapes[1] && !etapes[2]">
          <label for="email" class="titre">Doit-on vous appeler ... ?</label>
          <div class="input-group">
            <input id="mme" name="mme" class="btn choix_genre" type="button" value="Madame" v-on:click="valider_etape_3('mme')">
            <input id="mr" name="mr" class="btn choix_genre" type="button" value="Monsieur" v-on:click="valider_etape_3('mr')">
            <input id="x" name="x" class="btn choix_genre" type="button" value="Ni l'un ni l'autre" v-on:click="valider_etape_3('x')">
          </div>
        </div>
        <div class="etape-4" v-show="etapes[0] && etapes[1] && etapes[2] && !etapes[3]">
          <label for="email" class="titre">On termine avec un bon mot de passe !</label>
          <div class="input-group">
            <input 
                id="password"
                name="password" 
                :type="passwordType"
                maxlength="48"
                placeholder="Au moins 8 caractères" 
                v-model="utilisateur.password" />
            <span 
              class="decouvrir mdi mdi-eye"
              v-show="passwordType == 'password'"
              @click="passwordType = 'text'">
            </span>
            <span 
              class="decouvrir mdi mdi-eye-off"
              v-show="passwordType == 'text'"
              @click="passwordType = 'password'">
            </span>
          </div>
          <div class="regles password conteneur" v-if="!is_valid_password() && utilisateur.password && utilisateur.password.length > 0">
            <div class="regle" :class="{ 'valide': utilisateur.password.length >= 8}">* Au moins 8 caractères</div>
            <div class="regle" :class="{ 'valide': /[A-Z]/.test(utilisateur.password)}">* Au moins une lettre majuscule</div>
            <div class="regle" :class="{ 'valide': /[a-z]/.test(utilisateur.password)}">* Au moins une lettre minuscule</div>
            <div class="regle" :class="{ 'valide': /[@$!%*#?&]/.test(utilisateur.password)}">* Au moins un caractère spécial (&, ?, #, *, %, @, $, ! autorisés)</div>
            <div class="regle" :class="{ 'valide': /[0-9]/.test(utilisateur.password)}">* Au moins un chiffre</div>
            <div class="regle" v-show="/[;,§€£¤°:\.\+=\|\'\~\^\\\/\-_(){}\[\]]/.test(utilisateur.password)">* Il y a un caractère non autorisé</div>
          </div>
          <div class="input-group" v-show="is_valid_password()">
            <input 
              id="confirmation"
              name="confirmation" 
              maxlength="48"
              :type="passwordType"
              placeholder="On prend les mêmes et on recommence !" 
              v-model="utilisateur.confirmation" />
            <span 
              class="decouvrir mdi mdi-eye"
              v-show="passwordType == 'password'"
              @click="passwordType = 'text'">
            </span>
            <span 
              class="decouvrir mdi mdi-eye-off"
              v-show="passwordType == 'text'"
              @click="passwordType = 'password'">
            </span>
          </div>

          <div class="input-group" v-show="is_valid_password()">
            <input 
              id="terminez" 
              name="terminez"
              class="btn"
              type="button" 
              value="C'est parti !" 
              :disabled="utilisateur.password != utilisateur.confirmation"
              v-on:click="valider_inscription()" />

          </div>
        </div>
        <div class="etape-5" v-show="etapes[0] && etapes[1] && etapes[2] && etapes[3]">
          <label for="email" class="titre">C'est terminé !</label>
          <div>
            <h4>Merci pour votre inscription. Allez vite poster un trajet ou réserver un covoiturage.</h4>
            <h5>(Vous venez de recevoir un mail à votre adresse {{ utilisateur.email }}. Vous pourrez à tout moment suivre les instructions indiquées pour valider votre email.)</h5>
          </div>
        </div>
      </div>
    </section><!-- #inscription -->

  </div>

</template>

<style>
#inscription {
    width: 100%;
    padding: 36px;
}

.inscription-content {
    text-align: center;
}

.inscription-content .input-group {
    margin: 18px 0;
}

#inscription .inscription-content .titre {
  color: #17a2b8;
  margin: 9px 0 39px 0;
  font-size: 25px;
  font-weight: 600;
}

#inscription .inscription-content [class^=etape-] {
  width: 39vw;
  margin: auto;
}

#inscription .inscription-content input {
  width: 84%;
  border: 1px solid #ccc;
  border-radius: 12px;
  height: 39px;
  margin: 0 auto;
  padding-left: 12px;
}

#inscription .inscription-content input:focus {
  border: 2px solid #17a2b8;
}

#inscription .inscription-content input[type=button] {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
}

.regles.password.conteneur {
  font-size: 11px;
  width: 84%;
  margin: 12px auto;
  text-align: left;
}

.regles.password.conteneur .regle {
  width: 39%;
  color: #d53e3e;
  background-color: #e7e7e7;
  padding: 7px;
  margin: 0px auto;
}

.regles.password.conteneur .regle.valide {
  color: #3ed54b;
}

@media(max-width: 480px) {  
  #inscription .inscription-content [class^=etape-] {
    width: 72vw !important;
  }
}
</style>


<script>
import axios from 'axios';
import constantes from '../constantes';

export default {
  data() {
    return {
      /* eslint-disable */
      regex_email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regex_password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[a-zA-Z\d@$!%*#?&]{8,}$/,
      /* eslint-enable */
      passwordType: 'password',
      utilisateur: {},
      date_naissance: null,
      etapes: [false, false, false, false],
      erreurs: {
        doublon_email: false,
        date_naissance_invalide: false
      }
    };
  },
  mounted() {
    // Titre de l'onglet
    document.title = 'Inscription gratuite pour un bon covoiturage. Pas cher et carrément plus cool. Covoiturage, partage et vie | SimoneVerte.fr';
    
    // Authenfication externe
    window.google.accounts.id.initialize({
      client_id: constantes.google_auth_api_client_id,
      callback: this.handleCredentialResponse,
      auto_select: true
    })
    window.google.accounts.id.renderButton(
      this.$refs.inscriptionViaGoogle, {
        text: 'signup_with',
        size: 'large',
        width: '300',
        theme: 'outline',
        logo_alignement: 'left'
      }
    )
  },
  watch: {
    date_naissance: function(val, old) {   
      let suppression = false;
      val = val.replace(/[^0-9/]+/g, '');

      // Cas des suppression de 
      if (old && old.length > val.length) {
        suppression = true;
        if (old.length == 3 || old.length == 5) {
          val.substring(0, val.length-1);
        }
      }

      this.date_naissance = val;

      if (!suppression && (this.date_naissance.length == 2 || this.date_naissance.length == 5)) {
        this.date_naissance = this.date_naissance + '/';
      }
      this.utilisateur.date_naissance = this.date_naissance;
    }
  },
  methods: {
    _date_for_DB: function() {
      let dd = this.date_naissance.substring(0, 2);
      let mm = this.date_naissance.substring(3, 5);
      let yyyy = this.date_naissance.substring(6);
      return yyyy + '-' + mm + '-' + dd;
    },
    valider_etape_1: function() {
      let v = this.regex_email.test(this.utilisateur.email);
      if (v) {
        this.$root.$emit("start_loading");

        axios(constantes.apiBaseUrl + '/utilisateurs?filter[email]=' + this.utilisateur.email)
        .then(res => {
          if (res.data.length == 0) {
            this.$set(this.etapes, 0, true);
          } else {
            this.erreurs.doublon_email = true;
          }
        })
        .catch(err => console.error(err))
        .finally(() => { this.$root.$emit("hide_loading") });
      }
    },
    valider_etape_2: function() {
      // Vérification de la date de naissance 
      
      let dd = parseInt(this.date_naissance.substring(0, 2));
      let mm = parseInt(this.date_naissance.substring(3, 5));
      let yyyy = parseInt(this.date_naissance.substring(6));

      if (yyyy < 1923 || yyyy > 2013 || mm < 1 || mm > 12 || dd < 1 || dd > 31) {
        this.erreurs.date_naissance_invalide = true;
      } else {
        this.$set(this.etapes, 1, true);
      }
    },
    valider_etape_3: function(civilite) {
      this.utilisateur.sexe = civilite === 'mme' ? 'F' : (civilite === 'mr' ? 'M' : 'X'); 
      this.$set(this.etapes, 2, true);
    },
    is_valid_password: function(nb_password) {
      let v = this.utilisateur.password
        && this.regex_password.test(this.utilisateur.password)
        && !(/[;,§€£¤°:\.\+=\|\'\~\^\\\/\-_(){}\[\]]/.test(this.utilisateur.password));

      if (nb_password && nb_password === 2) {
        v = v 
          && this.utilisateur.confirmation
          && this.regex_password.test(this.utilisateur.confirmation)
          && this.utilisateur.password === this.utilisateur.confirmation;
      }
      return v;
    },
    valider_inscription: function() {
      this.$root.$emit("start_loading");

      const bodyFormData = new FormData();
      bodyFormData.append('nom', this.utilisateur.nom);
      bodyFormData.append('prenom', this.utilisateur.prenom);
      bodyFormData.append('email', this.utilisateur.email);
      bodyFormData.append('sexe', this.utilisateur.sexe);
      bodyFormData.append('date_naissance', this._date_for_DB());
      bodyFormData.append('password', this.utilisateur.password);
      bodyFormData.append('password_confirmation', this.utilisateur.confirmation);

      axios({
        method: 'post',
        url: constantes.apiBaseUrl + '/register',
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(res => {
        if (res.data.id) {
          this.$set(this.etapes, 3, true);
        }
      })
      .catch(err => console.error(err))
      .finally(() => this.$root.$emit("hide_loading"));                      
    },
    handleCredentialResponse(response) {
      // Send the Google ID token to your server for verification
      const gUser = this.parseJwt(response.credential);
      console.log(gUser);
      // Make an API call to your Node.js server with the token
      axios.post(constantes.apiBaseUrl + '/register/google', gUser)
        .then(response => {})
        .catch(error => {console.err(error)});
    },
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
  }
}
</script>