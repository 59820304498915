<template>
  <div id="app">
    <v-app>
      <div id="main-loader" v-show="apploading">
        <v-row
          class="text-center"
          align="center"
          justify="center"
          style="height: 100vh"
        >
          <v-col cols="12" lg="1" md="1" sm="1">
            <button class="btn">
              <span class="mdi mdi-spin mdi-loading"></span>
            </button>
          </v-col>
        </v-row>
      </div>
      <!--==========================
        Header
      ============================-->
      <header
        id="header"
        v-sticky="{ zIndex: 150, stickyTop: 0, disabled: false }"
      >
        <div class="container">
          <div id="logo" class="pull-left" style="padding-left: 12px">
            <!-- <h1><router-link :to="{name: 'home'}" class="scrollto">Simone<span>Verte</span></router-link></h1> -->
            <!-- Uncomment below if you prefer to use an image logo -->
            <!-- <a href="#body"><img src="img/logo.png" alt="" title="" /></a>-->
            <router-link :to="{ name: 'home' }">
              <img src="@/assets/img/simoneverte-logo-horizontal.png" alt="Logo de simoneverte covoiturage" />
            </router-link>
          </div>

          <nav id="nav-menu-container" class="hidden-sm-and-down">
            <ul class="nav-menu">
              <!--<li>
                <router-link
                  :to="{ name: 'promotions' }"
                  class="menu-promo"
                  ><i class="mdi mdi-gift"></i>Promotions</router-link
                >
              </li>-->
              <!--<li>
                <router-link
                  :to="{ name: 'gaming' }"
                  style="color: red !important"
                  ><i class="mdi mdi-gamepad-variant"></i>Jouer</router-link
                >
              </li>-->
              <li>
                <router-link
                  :to="{ name: 'trouver' }"
                  ><i class="mdi mdi-magnify"></i>Trouver</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: 'proposer' }"
                  ><i class="mdi mdi-map"></i>Proposer un trajet</router-link
                >
              </li>
              <li v-show="!isAuthenticated">
                <router-link
                  :to="{ name: 'inscription' }"
                  ><i class="mdi mdi-pen"></i>S'inscrire</router-link
                >
              </li>
              <li v-show="!isAuthenticated">
                <router-link
                  :to="{ name: 'connexion' }"
                  ><i class="mdi mdi-account"></i>Connexion</router-link
                >
              </li>
              <li v-show="!isAuthenticated">
                <router-link
                  :to="{ name: 'sudoku' }"
                  ><i class="mdi mdi-numeric"></i>Sudoku</router-link
                >
              </li>
              <li v-if="isAuthenticated">
                <v-navigation-drawer permanent>
                  <v-list-item style="padding-left:8px" v-on:click="submenu = !submenu">
                    <v-list-item-avatar style="margin: auto">
                      <img
                        :src="profile.avatar | avatar"
                        alt="Image de profil"
                        v-show="profile.avatar && !avatar_par_defaut"
                        @error="avatar_par_defaut = true"
                      />
                      <img
                        src="@/assets/img/profil/profil_defaut.jpeg"
                        alt="Image de profil"
                        v-show="!profile.avatar || avatar_par_defaut"
                        style="width: 48px; height: 48px"
                      />
                    </v-list-item-avatar>
                    
                    <v-list-item-content
                      style="max-width: 18px;">
                      <span
                        v-show="notifications.length > 0"
                        class="bulle-notifications pointer"
                        style="
                          width: 18px;
                          height: 18px;
                          background-color: #fa2577;
                          border-radius: 50%;
                          color: white;
                          font-weight: bold;
                          text-align: center;
                          font-size: 0.75em;
                          padding-top: 0.12em;
                          display: block;
                          position: absolute;
                          left: 39px;
                          top: 30px;
                          z-index: 2;
                        "
                      >
                        {{ notifications.length }}
                      </span>
                    </v-list-item-content>

                    <v-list-item-content>
                      <v-list-item-title
                        >{{ profile.prenom | premiereLettreCapitale }}
                        {{ profile.nom }}</v-list-item-title
                      >
                    </v-list-item-content>

                    <v-icon v-show="!submenu">mdi mdi-chevron-right</v-icon>
                    <v-icon v-show="submenu">mdi mdi-chevron-up</v-icon>
                  </v-list-item>

                  <v-divider v-show="submenu"></v-divider>

                  <v-list style="padding:8px" dense v-show="submenu">
                    <v-list-item link>
                      <v-row class="text-center" justify="center">
                        <v-col
                          cols="2">
                          <v-list-item-icon >
                            <v-icon>mdi-view-dashboard</v-icon>
                          </v-list-item-icon>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <v-list-item-title v-on:click="dashboard()"
                              >Tableau de bord</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-list-item>

                    <v-list-item  link>
                      <v-row class="text-center" justify="center">
                        <v-col
                          cols="2">
                          <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                          </v-list-item-icon>
                        </v-col>
                        <v-col>
                          <v-list-item-content>
                            <v-list-item-title v-on:click="logout()"
                              >Déconnexion</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-list>
                </v-navigation-drawer>
              </li>
            </ul>
          </nav>
          <!-- #nav-menu-container -->

          <div
            class="hidden-md-and-up"
            style="
              display: flex;
              justify-content: space-between;
              margin-top: -25px;
            "
          >
            <!-- Menu mobile -->
            <v-toolbar>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-container v-on="on">
                    <v-row class="text-center" justify="center">
                      <v-col
                        style="display: grid"
                        v-show="
                          isAuthenticated && profile.avatar && !avatar_par_defaut
                        ">

                        <img
                          :src="profile.avatar | avatar"
                          alt="Image de profil"
                          @error="avatar_par_defaut = true"
                          style="
                            width: 48px;
                            height: 48px;
                            border-radius: 50%;
                            cursor: pointer;
                            margin-top: 18px;
                          "
                        />
                        <v-icon v-show="isAuthenticated && !submenu">mdi mdi-chevron-down</v-icon>
                      </v-col>
                      <v-col
                          v-show="
                            isAuthenticated &&
                            (!profile.avatar || avatar_par_defaut)
                          "
                          style="display: grid">
                        <img
                          src="@/assets/img/profil/profil_defaut.jpeg"
                          alt="Image de profil"
                          style="
                            width: 48px;
                            height: 48px;
                            border-radius: 50%;
                            cursor: pointer;
                            margin-top: 18px;
                          "
                        />
                        <v-icon v-show="isAuthenticated && !submenu">mdi mdi-chevron-down</v-icon>
                      </v-col>
                      <v-col v-show="!isAuthenticated">
                        <v-icon style="left: 4px; top: -3px">mdi-menu</v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <v-list class="mobile-menu">
                  <v-list-item
                    v-for="item in menu"
                    :key="item.icon"
                    class="pointer"
                    v-show="
                      item.authenticated == null ||
                      (!isAuthenticated && !item.authenticated) ||
                      (isAuthenticated && item.authenticated)
                    "
                    v-on:click="goTo(item.routename, item.functionname)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </div>
        </div>
      </header>
      <!-- #header -->

      <div>
        <router-view name="vue-principale" :key="$route.fullPath" />
      </div>

      <!--==========================
        Footer
      ============================-->
      <footer id="footer">
        <div class="container">
          <v-row class="text-center" justify="center">
            <v-col
             cols="12" lg="6" md="6" sm="12">
              <router-link :to="{ name: 'trouver' }">
                <b>Top trajets</b>
              </router-link><br />
              <v-row>
                <v-col cols="12" lg="6" md="6" sm="12">
                  <router-link :to="{name: 'covoiturages-villes', params: { nom_ville_depart: 'Paris', nom_ville_arrivee: 'Lyon' }}">
                    Covoiturage Paris - Lyon
                  </router-link><br />
                  <router-link :to="{name: 'covoiturages-villes', params: { nom_ville_depart: 'Bordeaux', nom_ville_arrivee: 'Toulouse' }}">
                    Covoiturage Bordeaux - Toulouse
                  </router-link><br />
                  <router-link :to="{name: 'covoiturages-villes', params: { nom_ville_depart: 'Paris', nom_ville_arrivee: 'Strasbourg' }}">
                    Covoiturage Paris - Strasbourg
                  </router-link><br />
                  <router-link :to="{name: 'covoiturages-villes', params: { nom_ville_depart: 'Montpellier', nom_ville_arrivee: 'Nimes' }}">
                    Covoiturage Montpellier - Nîmes
                  </router-link><br />
                </v-col>
                <v-col
                  cols="12" lg="6" md="6" sm="12">
                  <router-link :to="{name: 'covoiturages-villes', params: { nom_ville_depart: 'Paris', nom_ville_arrivee: 'Brest' }}">
                    Covoiturage Paris - Brest
                  </router-link><br />
                  <router-link :to="{name: 'covoiturages-villes', params: { nom_ville_depart: 'Nantes', nom_ville_arrivee: 'Bordeaux' }}">
                    Covoiturage Nantes - Bordeaux
                  </router-link><br />
                  <router-link :to="{name: 'covoiturages-villes', params: { nom_ville_depart: 'Brest', nom_ville_arrivee: 'Nantes' }}">
                    Covoiturage Brest - Nantes
                  </router-link><br />
                  <router-link :to="{name: 'covoiturages-villes', params: { nom_ville_depart: 'Lille', nom_ville_arrivee: 'Rennes' }}">
                    Covoiturage Lille - Rennes
                  </router-link><br />
                </v-col>
              </v-row>
            </v-col>
            <v-col
             cols="12" lg="3" md="3" sm="12">
              <router-link target="_blank" :to="{ name: 'blog' }">
                <b>Le blog des covoitureurs</b>
              </router-link><br /><br />
              <router-link target="_blank" :to="{ name: 'blog-article', params: { nom_article: 'devenez-conducteur-simoneverte-2023' } }">
                Conducteurs de covoiturage : pourquoi SimoneVerte est fait pour vous ?
              </router-link><br />
              <router-link target="_blank" :to="{ name: 'blog-article', params: { nom_article: 'conducteur-covoiturage-comment-faire-economies' } }">
                Conducteurs de covoiturage : économisez !
              </router-link><br />
              <router-link target="_blank" :to="{ name: 'blog-article', params: { nom_article: '5-conseils-pour-reussir-son-covoiturage-d-ete-en-2024' } }">
                5 conseils pour réussir son covoiturage d'été 2024
              </router-link>
            </v-col>
            <v-col
             cols="12" lg="3" md="3" sm="12">
              <router-link :to="{ name: 'home' }">
                <b>SimoneVerte</b>
              </router-link><br /><br />
              <a @click="dialog_contact = true">Nous contacter</a>
              <br />
              <router-link :to="{ name: 'home' }">
                Dossier de presse
              </router-link>
              <br />
              <router-link :to="{ name: 'mentions' }">
                Mentions légales
              </router-link>
            </v-col>
          </v-row>
          <!-- <v-row class="text-center" justify="center">
            <v-col>
              <! -- <router-link :to="{ path: 'nous-connaitre' }">- ->
              <router-link :to="{ path: '/' }">
                Nous connaitre
              </router-link>
            </v-col>
            <v-col>
              <! -- <router-link :to="{ name: 'conditions' }">- ->
              <router-link :to="{ path: '/' }">
                Conditions générales
              </router-link>
            </v-col>
            <v-col>
              <router-link :to="{ name: 'mentions' }">
                Mentions légales
              </router-link>
            </v-col>
            <v-col>
              <a @click="dialog_contact = true">Nous contacter</a>
            </v-col>
          </v-row> -->
          
          <v-row class="text-center" justify="center">
            <v-col
             cols="12">
              <router-link :to="{ name: 'trouver' }" style="margin-bottom: 25px; margin-top: 18px; display: inline-block; font-weight: bold">
                Voyagez pas cher en covoiturage partout en France.
              </router-link><br />
              <v-row>
                <v-col cols="12" lg="3" md="3" sm="12"
                  v-for="i in 4"
                  :key="i"
                >
                  <div 
                    v-for="(region, index) in regions.slice((i - 1) * 5, i * 5)"
                    :key="index">
                    <router-link :to="{ name: 'covoiturages-region', params: { nom_region: region.nom } }">
                      {{ region.nom }} 
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
          <v-row class="text-center" justify="center">
            <div style="padding-top: 12px">
              <span>Louez un véhicule pour 1 &euro; avec <a style="background-color: rgb(21, 32, 61); display:inline-block; padding: 9px; border-radius: 12px; margin-left: 3px;" href="https://www.driiveme.com" target="_blank" alt="Driiveme"><img alt="logo driiveme" src="https://www.driiveme.com/Images/logo-driiveme-white.svg"></a></span>
            </div>
          </v-row>
          
          <v-row class="copyright" justify="center">
            <div>
              <a href="https://www.facebook.com/SimoneVerte-Covoiturage-102940332005410" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </a>
              <a href="https://www.instagram.com/simoneverte.covoiturage/" target="_blank" alt="Instagram">
                <v-icon>mdi-instagram</v-icon>
              </a>
            </div>
          </v-row>
          
          <v-row class="text-center" justify="center">
            <div style="padding-top: 12px">
              <span>&copy; 2024 <strong>SimoneVerte - Covoiturage </strong></span>
              <br />
              <span><i>Covoiturage innovant pas cher entre particuliers dans toute la France</i>.</span>
              <br />
              <span>Tous droits réservés.</span>
            </div>
          </v-row>
        </div>
      </footer>
      <!-- #footer -->

      <!-- CHAT BAR BLOCK -->
      <!--
      <div class="gaming-trigger">
        <button  
          @click="$router.push({ name: 'gaming' })">
          Jouez !
        </button>
      </div>
      -->

      <!-- CHAT BAR BLOCK -->
      <div class="chat-bar-trigger" 
        v-show="!chatbot_open">
        <button  
          @click="showMainChatbot()">
          <img
            src="@/assets/img/chattons-ensemble.jpg"
            alt="Chattons ensemble !"
            style="width:48px; height:48px; border-radius:50%; border:2px solid #20c997; "
            />
        </button>
      </div>

      <!-- Chatbot -->
      <div class="chat-bar-collapsible" 
        v-show="chatbot_open">
        <button  
          id="chat-button" 
          class="collapsible" 
          @click="chatbot_open = false">
          <i id="chat-icon" style="color: #fff;" class="mdi mdi-wechat" />
          &nbsp; &nbsp; <span>{{ chatbotConfiguration.titre }}</span>
          <span style="display: inline-block; padding-left:16px; font-weight: bold;">x</span>
        </button>

        <div :class="{'content-chatbot': !chatbot_open, 'full-chat-block': chatbot_open}">
          <div class="full-chat-block">
            <!-- Message Container -->
            <div class="outer-container">
              <div class="chat-container">
                <!-- Messages -->
                <div id="chatbox">
                  <div
                    v-for="msg in messages" 
                    :key="msg.id" 
                    :class="{'botText': msg.isBot, 'userText': !msg.isBot}">
                    
                    <v-list-item-avatar>
                      <img
                        :src="msg.avatar | avatar"
                        alt="Image de profil"
                        v-show="msg.avatar"
                        @error="avatars_par_defaut[msg.utilisateur_id] = true"
                      />
                      <img
                        src="@/assets/img/profil/profil_defaut.jpeg"
                        alt="Image profil par défaut"
                        v-show="!msg.avatar && avatars_par_defaut[msg.utilisateur_id] === true"
                        style="width: 48px; height: 48px"
                      />
                    </v-list-item-avatar>
                    
                    <span v-show="!msg.isBot" style="display:inline-block"> 
                      <span style="font-size:0.63em; font-weight:bold; margin:0px; padding:0px 0px 12px 0px; font-style:italic;">Vous</span><br /> 
                      {{ msg.texte }} <br />
                      <span style="font-size:0.63em; font-weight:bold; margin:0px; padding:12px 0px 0px 0px; text-align:right; display:inline-block; width:100%">{{ msg.time | heure }}</span>
                    </span>
                    <div 
                      v-show="msg.isBot">
                      <span style="font-size:0.63em; font-weight:bold; color:#777; margin:0px; padding:0px 0px 12px 0px; font-style:italic;">{{ msg.prenom }}</span><br /> 
                      <div v-html="msg.texte" style="padding:0px; margin:0px; display:inline" /><br />
                      <span style="font-size:0.63em; font-weight:bold; color:#777; margin:0px; padding:0px; text-align:right; display:inline-block; width:100%">{{ msg.time | heure }}</span>
                    </div>
                  </div>
                </div>

                <!-- User input box -->
                <div class="chat-bar-input-block">
                  <div id="userInput"> 
                    <input 
                      id="textInput" 
                      class="input-box" 
                      type="text" 
                      name="msg"
                      v-model="currentMessage"
                      @keyup.enter="sendMessage"
                      placeholder="Entrez votre message" />
                    <p></p>
                  </div>

                  <div class="chat-bar-icons"> 
                    <i id="chat-icon" 
                      class="mdi mdi-send" 
                      @click="sendMessage"
                      style="color: #333; cursor: pointer" />
                    <!-- <i id="chat-icon" style="color: crimson;" class="mdi mdi-heart" /> -->
                  </div>
                </div>

                <div id="chat-bar-bottom">
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- Fin chatbot -->

      <v-dialog v-model="dialog_contact" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Nous Contacter</span>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-container>
            <div class="input-group" style="">
              <input
                name="nom"
                type="text"
                maxlength="180"
                placeholder="Votre nom"
                v-model="nom"
              />
            </div>
            <div class="input-group" style="margin-top: 12px">
              <input
                name="email"
                type="email"
                maxlength="180"
                placeholder="Votre adresse email"
                v-model="email"
              />
            </div>
            <div class="input-group">
              <textarea
                rows="5"
                cols="33"
                maxlength="250"
                placeholder="Votre message ici"
                style="width: 84%"
                v-model="message"
              >
              </textarea>
            </div>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="envoyerMessagePublic()">
              Envoyer
            </v-btn>
            <v-btn color="blue darken-1" text @click="dialog_contact = false">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Pop-up validation de l'envoie de l'email-->
      <v-dialog
        v-model="dialog_comfirmation"
        hide-overlay
        persistent
        width="300"
      >
        <v-alert
          type="success"
        >
          Votre demande de prise de contact à été envoyé!
        </v-alert>
      </v-dialog>

      <v-dialog
        v-model="dialog_changement_mobile"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >Code de vérification de téléphone envoyé</v-toolbar>
          <v-card-text>
            <h1 style="text-align:center; margin-top:18px;">Vérification téléphone</h1>
            <p>
              Vous venez d'enregistrer ou modifier votre numéro de téléphone. 
              Nous allons vous envoyer un SMS avec un code unique valable 24 heures pour vérifier 
              votre nouveau numéro.<br />
              Merci pour votre confiance !
            </p>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog_changement_mobile = false"
            >Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-overlay :value="chatbot_open"></v-overlay>
      <a href="#" id="back-to-top">
        <i class="mdi mdi-chevron-up"></i>
      </a>
    </v-app>
  </div>
</template>


<style>
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  background: #fff;
  color: #444;
  font-family: "Open Sans", sans-serif;
  /*font-size: 0.93em;*/
}

a {
  color: #50d8af;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #20c997;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

textarea {
  width: 90%;
  border: 1px dotted #aaa;
  border-radius: 12px;
  margin: 12px auto;
  padding: 7px;
}

input {
  width: 84%;
  border: 1px solid #ccc;
  border-radius: 12px;
  height: 39px;
  margin: 0 auto;
  padding-left: 12px;
}

input[type="text"] {
  background-color: white;
}

input:focus {
  border: 2px solid #17a2b8;
}

input[type="button"] {
  cursor: pointer;
}

input[type="button"]:disabled {
  cursor: crosshair;
  opacity: 0.33;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0 0 16px 0;
  padding: 0;
}

h2 {
  font-size: 1.29rem;
}

h3,
h4 {
  font-size: 1.2rem;
}

.pointer {
  cursor: pointer;
}

/* Back to top button */

#back-to-top {
  position: fixed;
  display: none;
  background: #50d8af;
  color: #fff;
  padding: 7px 9px 7px 9px;
  font-size: 16px;
  border-radius: 2px;
  right: 15px;
  bottom: 61px;
  transition: background 0.5s;
}

#back-to-top:focus {
  background: #50d8af;
  color: #fff !important;
  outline: none;
}

#back-to-top:hover {
  background: #17a2b8;
  color: #fff !important;
}

#main-loader {
  width: 100%;
  height: calc(100% - 84px);
  position: absolute;
  top: 84px;
  left: 0;
  background: rgba(80, 216, 175, 0.45);
  z-index: 1000;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/

#topbar {
  background: #fff;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}

#topbar .contact-info a {
  line-height: 1;
  color: #555;
}

#topbar .contact-info a:hover {
  color: #50d8af;
}

#topbar .contact-info i {
  color: #50d8af;
  padding: 4px;
}

#topbar .contact-info .fa-phone {
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid #e9e9e9;
}

#topbar .social-links a {
  color: #555;
  padding: 4px 12px;
  display: inline-block;
  line-height: 1px;
  border-left: 1px solid #e9e9e9;
}

#topbar .social-links a:hover {
  color: #50d8af;
}

#topbar .social-links a:first-child {
  border-left: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
  padding: 16px 0;
  height: 84px;
  transition: all 0.5s;
  z-index: 997;
  background: #fff;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}

#header .container {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  padding: 7px;
}

#header #logo h1 {
  font-size: 27px;
  margin: 0;
  padding: 0;
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

#header #logo h1 a {
  color: #17a2b8;
  line-height: 1;
  display: inline-block;
}

#header #logo h1 a span {
  color: #50d8af;
}

#header #logo img {
  padding: 0;
  margin: 0;
  height: 48px;
  margin-top: -12px;
}

/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Nav Menu Essentials */

#nav-menu-container .nav-menu,
#nav-menu-container .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

#nav-menu-container .nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

#nav-menu-container .nav-menu li {
  position: relative;
  white-space: nowrap;
}

#nav-menu-container .nav-menu > li {
  float: left;
}

#nav-menu-container .nav-menu li:hover > ul,
#nav-menu-container .nav-menu li.sfHover > ul {
  display: block;
}

#nav-menu-container .nav-menu ul ul {
  top: 0;
  left: 100%;
}

#nav-menu-container .nav-menu ul li {
  min-width: 180px;
}

.container .v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.container .v-menu__content {
  top: 70px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 22px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 8px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
  float: right;
  margin: 0;
}

/* Nav Meu Styling */
#nav-menu-container .nav-menu a,
#nav-menu-container .nav-menu .v-list-item .v-list-item__title {
  padding: 10px 8px;
  text-decoration: none;
  display: inline-block;
  color: #555;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 13px;
  outline: none;
  text-align: center;
}

#nav-menu-container .nav-menu li:hover,
#nav-menu-container .nav-menu li:hover > a,
#nav-menu-container .nav-menu .menu-active > a,
#nav-menu-container .nav-menu a.router-link-active {
  color: #50d8af;
}

#nav-menu-container .nav-menu > li {
  margin-left: 36px;
}
#nav-menu-container .nav-menu > li i {
  display: inline-block;
  margin-right: 12px;
}

#nav-menu-container .nav-menu ul {
  margin: 4px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}

#nav-menu-container .nav-menu ul li {
  transition: 0.3s;
}

.mobile-menu {
  position: relative;
  margin-top: 27px;
}

a.menu-promo {
  padding: 10px;
  color: #f719d9 !important;
  transition: 0.3s;
  display: block;
  font-size: 13px;
  text-transform: none;
}

#nav-menu-container .nav-menu ul li a {
  padding: 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 13px;
  text-transform: none;
}

#nav-menu-container .nav-menu ul li:hover > a {
  color: #50d8af;
}

#nav-menu-container .nav-menu ul ul {
  margin: 0;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/

/* Gaming
------------------------------*/
.gaming {
  background-color: #fff;
}

/* Sections Header
--------------------------------*/
.section-header {
  margin-bottom: 30px;
}

.gaming h2,
.section-header h2 {
  font-size: 32px;
  color: #17a2b8;
  font-weight: 700;
  position: relative;
  padding-bottom: 20px;
}

.section-header h2 {
  text-transform: uppercase;
}

.section-header h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #50d8af;
  bottom: 0;
  left: 0;
}

.section-header p {
  padding: 0;
  margin: 0;
}

/*--------------------
  Surcharge vuetify
  --------------------*/
.v-overlay,
.v-dialog {
  width: 100vw !important;
}

/*-------------------
  . btn
  ---------------------*/
.btn {
  /* font-family: "Raleway", sans-serif; */
  color: white;
  border-radius: 12px;
  background-color: #17a2b8;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 14px;
  padding: 6px;
  min-height: 39px;
  margin: 10 auto;
  transition: 0.5s;
}

.btn.share {
  color: white;
  border: 2px solid;
  width: 84%;
  margin: 9px auto;
}
.btn.share.facebook {
  background-color: #3b5998;
}
.btn.share.whatsapp {
  background-color: #25d366;
}
.btn.share.telegram {
  background-color: #0088cc;
}
.btn.share.email {
  background-color: #fff;
  color: #444;
}
.btn.share.sms {
  background-color: #ccc;
  color: #333;
}

.btn.centre {
  margin: 12px auto !important;
}

.btn.large {
  padding: 6px 18px !important;
  margin: 9px;
  height: auto;
}

.btn.vert,
.btn.suggere {
  background-color: #50d53e;
}

.btn.bleu-clair {
  background-color: #5fe7fd;
}

.btn.rose {
  background-color: #fc9cbd;
}

.btn.rouge {
  background-color: #d5503e;
}

.btn.valide {
  background-color: #50d53e;
  color: white;
  padding: 3px 9px;
}

.btn.neutre {
  background-color: #777;
  color: white;
  padding: 3px 9px;
}

.btn.choix_genre {
  width: 12vw !important;
}

.btn.disabled {
  background-color: #aaa;
  opacity: 0.75;
  cursor: auto !important;
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
  background: #f2f5f8;
  padding: 30px 0 30px 0;
  font-size: 14px;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #555;
}

#footer .credits a {
  color: #17a2b8;
}

.v-dialog__content {
  width: 100vw !important;
}

@media (min-width: 768px) {
  #contact .contact-address,
  #contact .contact-phone,
  #contact .contact-email {
    padding: 20px 0;
  }

  #contact .contact-phone {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
}

@media (min-width: 769px) {
  #call-to-action .cta-btn-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  #back-to-top {
    padding: 7px 9px 7px 9px;
    bottom: 61px;
  }

  #header {
    padding: 20px 0;
    height: 74px;
  }

  #header #logo h1 {
    font-size: 34px;
  }

  #header #logo img {
    max-height: 36px;
  }

  #nav-menu-container {
    display: none;
  }

  #about .about-img {
    height: auto;
  }

  #about .about-img img {
    margin-left: 0;
    padding-bottom: 30px;
  }
  
  #nav-menu-container .nav-menu a,
  #nav-menu-container .nav-menu .v-list-item .v-list-item__title {
    font-size: 12px;
  }  
}

@media (max-width: 767px) {
  #intro .intro-content h2 {
    font-size: 25px;
  }

  #services .box .box {
    margin-bottom: 20px;
  }

  #services .box .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
  }

  #services .box h4,
  #services .box p {
    margin-left: 0;
    text-align: center;
  }

  #pricing .testimonial-item {
    margin: 30px 10px;
  }
}

@media (max-width: 576px) {
  #contact #google-map {
    margin-top: 20px;
  }
  .btn.choix_genre {
    width: 75% !important;
    margin: 12px auto !important;
  }
  #intro .intro-content h2 {
    font-size: 18px;
  }
  .btn.share {
    font-size: 12px;
  }
}

/*
 * Gaming 
 *********************************/ 
.gaming-trigger {
  position: fixed;
  /* La barre bleue reste fixe */
  bottom: 18px;
  right: 120px;
  z-index: 999999;
  background-color: red;
  padding: 12px;
  border-radius: 12px;
  color: #fff;
}

/* 
 * Chatbot
 *********************************/
.chat-bar-collapsible,
.chat-bar-trigger {
  position: fixed;
  /* La barre bleue reste fixe */
  bottom: 18px;
  right: 60px;
  z-index: 999999;
}

.chat-bar-collapsible {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.chat-bar-trigger {
  bottom: 18px;
}

.collapsible {
  /* La zone cliquable */
  background-color: rgb(82, 151, 255);
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 350px;
  text-align: left;
  outline: none;
  font-size: 18px;
  border-radius: 10px 10px 0px 0px;
  border: 3px solid white;
  border-bottom: none;
}

.content-chatbot { 
  max-height: 0; 
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}

.full-chat-block {
  /* Css montrer la conv */
  width: 350px;
  background: white;
  text-align: center;
  overflow: auto;
  scrollbar-width: none;
  height: max-content;
  transition: max-height 0.2s ease-out; 
}

.outer-container {
  min-height: 77vh;
  top: 0%;
  position: relative;
}

#chatbox
{
  height: calc(77vh - 45px);
  overflow: auto;
  padding-top: 12px;
}

.chat-container {
  max-height: 77vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  scroll-behavior: smooth;
  hyphens: auto;
}

.chat-container::-webkit-scrollbar {
  display: none;
}

.chat-bar-input-block {
  /* Css la partie qui entoure là où il faut ecrire */
  display: flex;
  float: left;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: rgb(235, 235, 235);
  padding: 10px 0px 10px 10px;
}

.chat-bar-icons {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 27%;
  float: right;
  font-size: 20px;
  padding-left: 7px;
}

#chat-icon:hover {
  /* Permet de savoir que nous sommes placés sur les icons */
  opacity: 0.7;
}

/* Chat bubbles */
#userInput {
  /* Largeur de la barre où il faut ecrire */
  width: 72%;
}

.input-box {
  /* La barre où il faut ecrire */
  float: left;
  border: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  color: #000;
  background-color: white;
  outline: none
}

.userText,
.botText {
  /* Message Send qui se trouve à l'intérieur de la boite */
  color: white;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  clear: both;
  display: flex;
  align-items: flex-start;
}

.userText > span,
.userText > div:not(.v-avatar),
.botText > span,
.botText div:not(.v-avatar) {
  /* box inside */
  line-height: 1.5em;
  display: inline-block;
  background: #5ca6fa;
  padding: 10px;
  border-radius: 12px;
  max-width: 80%;
  margin: 0px 12px 12px 12px;
  animation: floatup .5s forwards;
}

.botText > span,
.botText div:not(.v-avatar) {
  background: #e0e0e0;
  border-top-left-radius: 0px;
}

.userText > span,
.userText > div:not(.v-avatar) {
  border-top-right-radius: 0px;
}

.botText {
  color: #000;
  justify-content: left;
  flex-direction: row-reverse;
}

.userText {
  justify-content: right;
  flex-direction: row;
}

@keyframes floatup {
  from {
    transform: translateY(14px);
    opacity: .0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media screen and (max-width:600px) {
  .full-chat-block {
    width: 100%;
    border-radius: 0px;
  }

  .chat-bar-collapsible {
    position: fixed;
    bottom: 1vh;
    right: 3.5vw;
    width: 93vw;
  }

  .collapsible {
    width: 100%;
    border: 0px;
    border-top: 3px solid white;
    border-radius: 10px 10px 0px 0px;
  }
}
</style>

<script>
import VueSticky from "vue-sticky";
import router from "./router";
import { mapGetters } from "vuex";
import axios from 'axios';
import constantes from './constantes';
import listeDepartementsFrance from './util/liste_departements_france';
import { AUTH_LOGOUT } from "./store/actions/auth";
import { USER_REQUEST } from "./store/actions/user";
import moment from 'moment';

export default {
  data() {
    return {
      departementsValues: listeDepartementsFrance,
      dialog_promo: true,
      dialog_contact: false,
      dialog_comfirmation: false,
      dialog_changement_mobile: false,
      avatar_par_defaut: false,
      avatars_par_defaut: [],
      notifications: [],
      apploading: false,
      submenu: false,
      nom: '',
      email: '',
      message: '',
      menu: [
        /*{
          icon: "mdi-gift",
          authenticated: null,
          label: "Promotions",
          routename: "promotions",
        },*/
        {
          icon: "mdi-magnify",
          authenticated: null,
          label: "Trouver",
          routename: "trouver",
        },
        {
          icon: "mdi-map",
          authenticated: null,
          label: "Proposer un trajet",
          routename: "proposer",
        },
        {
          icon: "mdi-pen",
          authenticated: false,
          label: "S'inscrire",
          routename: "inscription",
        },
        {
          icon: "mdi-account",
          authenticated: false,
          label: "Connexion",
          routename: "connexion",
        },
        {
          icon: "mdi-numeric ",
          authenticated: false,
          label: "Sudoku",
          routename: "sudoku",
        },
        {
          icon: "mdi-view-dashboard",
          authenticated: true,
          label: "Tableau de bord",
          functionname: "dashboard",
        },
        {
          icon: "mdi-account-outline",
          authenticated: true,
          label: "Mon profil",
          functionname: "editprofil",
        },
        {
          icon: "mdi-account-box-outline",
          authenticated: true,
          label: "Mon avatar",
          functionname: "editavatar",
        },
        /*{
          icon: "mdi-paperclip",
          authenticated: true,
          label: "Mes documents",
          functionname: "editdocuments",
        },*/
        {
          icon: "mdi-car-estate",
          authenticated: true,
          label: "Mes trajets conducteurs",
          functionname: "showtrajetsConducteur",
        },
        {
          icon: "mdi-car-sports",
          authenticated: true,
          label: "Mes trajets passagers",
          functionname: "showtrajetsPassager",
        },
        {
          icon: "mdi-logout-variant",
          authenticated: true,
          label: "Déconnexion",
          functionname: "logout",
        },
      ],
      // Chatbot
      chatbot_open: false,
      chatbotConfiguration: {
        titre: 'Besoin d\'aide ? Discutons-en !',
        channel_trajet_id: null,
      },
      currentMessage: '',
      messages: [
        {
          id: 1,
          texte: 'Bonjour je suis Simon ! J\'espère que vous allez bien. Comment puis-je vous aider ?',
          isBot: true,
          prenom: 'Simon Bot'
        },
      ],
    };
  },
  directives: {
    sticky: VueSticky,
  },
  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "authStatus"]),
    profile() {
      return this.getProfile;
    },
    regions() {
      let regions = [];

      this.departementsValues.forEach(departement => {
        let regionName = String(departement["region_name"]);
        
        let region = regions.filter(r => r.nom === regionName)[0];
        
        if (!region) {
          region = {};
          region.nom = regionName;
          region.departements = [];
          region.departements.push(departement)
          regions.push(region);
        } else {
          region.departements.push(departement)
          regions.map(r => r.nom === regionName ? region : r);
        }
      });

      return regions.sort((a, b) => a.nom.localeCompare(b.nom));
    }
  },
  mounted: function () {
    
    document.getElementById("back-to-top").click(function () {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return false;
    });

    this.$root.$on("start_loading", () => {
      this.showLoading();
      this.$root.globalLoading = true;
    });

    this.$root.$on("hide_loading", () => {
      this.$root.globalLoading = false;
      this.hideLoading();
    });

    this.$root.$on("reset_meta_description", () => {
      this.resetDescriptionMetaTag();
    });

    this.$root.$on("change_meta_description", (newContent) => {
      this.changeDescriptionMetaTag(newContent);
    });

    this.$root.$on("set_chatbotconfiguration_open", (chatbotConfiguration) => {
      this.chatbotConfiguration = chatbotConfiguration;
      
      if (this.chatbotConfiguration.channel_trajet_id) {
        this.$api.get('/message-chat-covoiturage/' + this.chatbotConfiguration.channel_trajet_id)
        .then(response => {
            this.messages = response.data.map(m => {
              var message = {},
                datetimeArray = m.time.split(' ');
              message.texte = m.text;
              message.prenom = m.prenom;
              message.date = datetimeArray[0];
              message.time = datetimeArray[1];
              message.avatar = m.avatar;
              message.isBot = this.profile.id != m.utilisateur_id;

              return message;
            });

            // Message de bienvenue 
            if (this.messages.length == 0) {
              this.messages.push({
                id: 1,
                texte: 'Bonjour je suis Simon le robot ! J\'espère que vous allez bien. Ce chat vient d\'être ouvert, alors n\'hésitez pas à vous présenter aux autres covoitureurs et / ou à donner des précisions par rapport au trajet.',
                isBot: true,
                time: moment().format("HH:mm"),
                prenom: 'Simon Bot'
              });
            }
        })
        .catch(err => {
          console.error(err);
          this.$router.push({ name: 'erreur404' });
        });

        var channel = this.$pusher.subscribe('channel-chat-covoiturage.' + this.chatbotConfiguration.channel_trajet_id);
        
        /*channel.bind("pusher:subscription_succeeded", () => {
          console.log("YESSSSSSSSSSSSSSSSSSSSSSSSS !");
        });*/

        var that = this;
        channel.bind('event-nouveau-message-chat-covoiturage', data => {
          
          var message = {};
          if (data.time) {
            var datetimeArray = data.time.split(' ');
            message.date = datetimeArray[0];
            message.time = datetimeArray[1];
          }
          message.texte = data.text;
          // message.prenom = data.prenom;
          // message.avatar = data.avatar;
          message.isBot = that.profile.id != data.utilisateur_id;

          that.messages.push(message);
        });
      }
      this.chatbot_open = true;
    });

    this.$root.$on('print_dialog_changement_mobile', () => {
      this.dialog_changement_mobile = true;
    }); 

    if (this.profile.id && this.isAuthenticated) {
      
      this.showLoading();

      // Récupération des messages
      this.$api.get("/utilisateurs/" + this.profile.id + "/notifications")
      .then((res) => {
        console.log(this.notifications);
        this.notifications = res.data;
      })
      .finally(() => this.hideLoading());
    }

    this.sessionEnCours();

    this.detectBottomScroll();
  },
  methods: {
    showMainChatbot: function() {
      this.chatbotConfiguration = {
        titre: 'Besoin d\'aide ? Discutons-en !',
        channel_trajet_id: null,
      };
      this.currentMessage = '';
      this.messages = [
        {
          id: 1,
          texte: 'Bonjour je suis Simon ! J\'espère que vous allez bien. Comment puis-je vous aider ?',
          isBot: true,
          time: moment().format("HH:mm"),
          prenom: 'Simon Bot'
        },
      ];
      this.chatbot_open = true;
    },
    sendMessage() {
      if (this.chatbotConfiguration.channel_trajet_id == null) {
        this.sendToSimonBot();
      }
      else {
        this.sendToChannel();
      }
    },
    sendToSimonBot() {
      if (this.currentMessage != '') {
        this.messages.push(
          { 
            id: this.messages.length+1,
            texte: this.currentMessage,
            isBot: false,
            time: moment().format("HH:mm"),
          }
        );
        this.currentMessage = '';
      }

      if (this.messages.length < 7) {
        // La réponse de Simon 
        window.setTimeout(() => {
          this.messages.push(
            { 
              id: this.messages.length + 1,
              texte: '🥰🥰🥰',
              isBot: true,
              time: moment().format("HH:mm"),
              prenom: 'Simon Bot'
            }
          );
        
          window.setTimeout(() => {
            this.messages.push(
              { 
                id: this.messages.length + 1,
                texte: 'Voici quelques points qui pourraient vous intéresser :',
                isBot: true,
                time: moment().format("HH:mm"),
                prenom: 'Simon Bot'
              }
            );

            window.setTimeout(() => {
              this.messages.push(
                { 
                  id: this.messages.length + 1,
                  texte: '<a href="https://www.simoneverte.fr/blog-covoiturage/conducteur-covoiturage-comment-faire-economies" target="_blank">Comment vous garantir des économies en proposant votre trajet sur SimoneVerte ?</a>',
                  isBot: true,
                  time: moment().format("HH:mm"),
                  prenom: 'Simon Bot'
                }
              );
            
              this.messages.push(
                { 
                  id: this.messages.length + 1,
                  texte: '<a href="#">Comment les passagers SimoneVerte bénéficient d\'un rapport qualité / prix exceptionnel ?</a>',
                  isBot: true,
                  time: moment().format("HH:mm"),
                  prenom: 'Simon Bot'
                }
              );
            }
            , 750);

          }
          , 1500);

        }
        , 2500);

      } else {
        window.setTimeout(() => {
          this.messages.push(
            { 
              id: this.messages.length + 1,
              texte: '<p>Merci 🥰🥰🥰 !</p><p>Je ne suis pas encore suffisament entrainé pour répondre à toutes les questions.</p><p>Cependant, vous pouvez nous envoyer un message en cliquant sur <b>"nous contacter" en bas de page</b> et nous vous recontacterons au plus vite.</p><p><b>&Agrave; très bientôt !</b></p>',
              isBot: true,
              time: moment().format("HH:mm"),
              prenom: 'Simon Bot'
            }
          );
        }, 1200);
      }

      /*
      axios.get('https:/wwww.ddddd.Ddd=${this.currentMessage}')
      .then(res =>{
        this.messages.push({
          id: this.messages.length+1,
          texte: this.res.data.output,
          isBot: true,
        })
      })
      */
    },
    sendToChannel() {
      if (this.currentMessage != '') {
        var bodyFormData = new FormData();
        bodyFormData.append('texte', this.currentMessage);
        bodyFormData.append('utilisateur_id', this.profile.id);
        
        this.$api.post('/message-chat-covoiturage/' + this.chatbotConfiguration.channel_trajet_id, bodyFormData)
        .then((resp) => {
          console.log(resp);
          this.currentMessage = '';
        });
      }
    },
    sessionEnCours: function () {
      if (!this.profile || !this.profile.id) {
        this.$store
          .dispatch(USER_REQUEST)
          .then(() => {
            console.log("Refresh token !!!");
            this.$root.$emit("refreshed_user");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    detectBottomScroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight >=
          document.documentElement.offsetHeight - 60;

        if (bottomOfWindow) {
          document.getElementById("back-to-top").style.display = "block";
          this.$root.$emit("scrolled_to_bottom");
        } else {
          document.getElementById("back-to-top").style.display = "none";
        }
      };
    },
    changeDescriptionMetaTag: function(newContent) {
      document.querySelector('meta[name="description"]').setAttribute("content", newContent);
    },
    resetDescriptionMetaTag: function() {
      document.querySelector('meta[name="description"]')
        .setAttribute(
          "content", 
          "Simoneverte | le covoiturage longue distance, pas cher et innovant."
        );
    },
    showLoading: function (forceStop) {
      if (!this.apploading) {
        this.apploading = true;
        if (forceStop) {
          setTimeout(() => {
            this.apploading = false;
          }, 1800);
        }
      }
    },
    hideLoading: function () {
      if (this.apploading) {
        this.apploading = false;
      }
    },
    headerGoTo: function (_name) {
      if (_name) {
        router.push({ name: _name });
        this.showLoading(true);
      }
    },
    logout: function () {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => {
          router.push({ name: "home" });
          this.$router.go();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            router.push({ name: "connexion" });
          }
        })
        .finally(() => {
          this.submenu = false;
          console.log("Déconnexion!");
        });
    },
    dashboard: function () {
      this.submenu = false;
      router.push({ path: "/utilisateurs" });
    },
    editprofil: function () {
      this.submenu = false;
      router.push({ path: "/utilisateurs" + "/infos" });
    },
    editavatar: function () {
      this.submenu = false;
      router.push({
        path: "/utilisateurs" + "/infos",
        query: { info: "photo" },
      });
    },
    editdocuments: function () {
      this.submenu = false;
      router.push({
        path: "/utilisateurs" + "/infos",
        query: { info: "documents" },
      });
    },
    showtrajetsConducteur: function () {
      this.submenu = false;
      router.push({
        path: "/utilisateurs" + "/trajets?type=conducteur",
      });
    },
    showtrajetsPassager: function () {
      this.submenu = false;
      router.push({
        path: "/utilisateurs" + "/trajets?type=passager",
      });
    },
    envoyerMessagePublic: function() {
      
      var bodyFormData = new FormData();
      bodyFormData.append('nom', this.nom);
      bodyFormData.append('email', this.email);
      bodyFormData.append('message', this.message);

      this.$root.$emit("start_loading");
      
      axios({
        method: 'post',
        url: constantes.apiBaseUrl + '/admin/public-message',
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(() => {
        
      })
      .finally(() => {
        this.dialog_contact = false;
        this.dialog_comfirmation = true,
        setTimeout(() => (this.dialog_comfirmation = false), 4000),
        this.$root.$emit("hide_loading");
      })                  
    },
    goTo: function (routename, functionname) {
      if (functionname == null) {
        router.push({ name: routename });
      }
      switch (functionname) {
        case "dashboard":
          this.dashboard();
          break;
        case "editprofil":
          this.editprofil();
          break;
        case "editavatar":
          this.editavatar();
          break;
        case "editdocuments":
          this.editdocuments();
          break;
        case "showtrajetsConducteur":
          this.showtrajetsConducteur();
          break;
        case "showtrajetsPassager":
          this.showtrajetsPassager();
          break;
        case "logout":
          this.logout();
          break;
        default:
          break;
      }
      this.showLoading(true);
    },
  },
};
</script>